<template>
	<div>
	</div>
</template>

<script>
import eventApi from '@/api/event';
import { getAxiosErrorMessage } from '@/lib/helper';

export default {
  components: {
  },
  props: {
    isFromRegister: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFetching: true,
    };
  },
  computed: {
    code() {
      return this.$route.params.code;
    },
  },
  methods: {
    async submit() {
      this.isFetching = true;
      this.$vs.loading();
      const callback = (response) => {
        const data = response.data;

        this.isFetching = false;
        this.$vs.loading.close();

        // Set event
        const eventData = data.event;
        if (eventData) {
          localStorage.removeItem(eventData.slug);
          if (!this.isFromRegister) {
            this.$router.push(`/c/${eventData.link_code_value}?code=${this.code}`);
          }
        }
      };
      const errorCallback = (response) => {
        this.$vs.loading.close();
        this.isFetching = false;
        const message = getAxiosErrorMessage(response);
        if (message) {
          this.$vs.notify({
            title: this.$t('Access'),
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
        }
        this.$router.push('/error-404');
      };
      eventApi.getByCode(this.code, callback, errorCallback);
    },
  },
  mounted() {
    localStorage.removeItem('LAST_SESSION_URL');
  },
  created() {
    this.submit();
  },
  destroyed() {
  },
};
</script>
